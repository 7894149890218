import React from "react";
import LogRocket from "logrocket";
import { LOGROCKET_ID } from "@/lib/defaults";

export const identifyUser = (user) => {
    if (window.rudderanalytics) {
        // @ts-ignore
        window.rudderanalytics.identify(user);
    }

    if (LOGROCKET_ID) {
        LogRocket.identify(user);
    }
};


