import React from 'react';
import Image from 'next/image';
import { Heading2 } from '@/components/Views/Heading';
import NormalText from "@/components/Views/Text";

const InfoSection = ({ id, title, text, image, flipped, containerCss, itemsContainerCss, headerCss, imageContainerCss, mobileBackground }) => {

    return (
        <div id={id} className={` ${mobileBackground} pb-2 lg:pb-4 pt-0 lg:pt-2 px-4 sm:px-10 `}>
            <div
                className={` ${containerCss} blue- flex gap-3 justify-center items-center gap-16 sm:gap-16 max-w-[1000px]  mx-auto py-4 sm:py-14 lg:py-5`}
                // style={{ flexDirection: flipped ? 'row-reverse' : 'row' }}
            >
                {/*<div className={`${itemsContainerCss} red w-full sm:w-[65%] lg:w-[60%] h-[80%] flex flex-col justify-center items-start`}>*/}
                <div className={`${itemsContainerCss} red- w-full basis-full flex flex-col justify-center items-start`}>

                    <h2
                        className={` ${headerCss} balanced text-center sm:text-left text-black font-semibold mx-right font-Poppins max-w-[100%] w-full text-2xl md:text-xl lg:text-[24px] xl:text-[30px] xl:leading-tight`}
                    >
                        {title}
                    </h2>

                    <div className={`${imageContainerCss}  sm:hidden w-full basis-full mb-6`}>
                        <Image src={image} />
                    </div>

                    {/*
                    <div className='text-center sm:text-left text-black text-base md:text-lg lg:text-[22px] font-light font-Poppins leading-normal lg:leading-[34px] max-w-[100%] sm:max-w-[400px] lg:max-w-[550px]'>
                        {text}
                    </div>
                    */}
                    {text && <NormalText
                        // balanced={true}
                        // className='text-black text-lg sm:text-xl md:text-[21px] font-normal font-Poppins text-center sm:text-left leading-[1.65] md:leading-[28px]  mt-0 max-w-[100%] sm:max-w-[400px] lg:max-w-[550px] !mx-0'
                        className='text-black !text-[15px] !sm:text-[16px] !md:text-[17px] !lg:text-[17px] !xl:text-[18px] !2xl:text-[18px] font-normal font-Poppins text-left leading-[1.65] md:leading-[1.6] mt-0 max-w-[100%] w-full !mx-0'

                        // className='text-white text-center sm:text-left font-Poppins mt-5 mx-auto'
                        style={{ fontFamily: 'Poppins' }}
                        text={text}
                    />}


                </div>

                <div className={`${imageContainerCss} hidden sm:block green- w-full basis-full`}>
                    <Image src={image} />
                </div>

            </div>
        </div>
    );
};

export default InfoSection;
