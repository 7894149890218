import { WEBSITE_URL } from './defaults';

export const createURL = (url, path) => {
    return new URL(path, url).href;
};

export const createWebsiteURL = (path) => {
    return createURL(WEBSITE_URL, path);
};

export const createUrlWithParams = (basePath, params) => {
    console.log('Creating URL with params', basePath, params);
    console.log('keys:', Object.keys(params));

    const query = Object.keys(params)
        .map((key) => {
            const value = params[key];
            console.log('.  key:', key, ' = ', value);
            if (value === undefined || value === null) {
                return null;
            }
            return `${encodeURIComponent(key)}=${encodeURIComponent(value ?? '')}`;
        })
        .filter((param) => param !== null)
        .join('&');

    console.log('query:', query);

    return `${basePath}?${query}`;
};

