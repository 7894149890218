import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import cx from 'classnames';
import { useRouter } from 'next/router';
import { checkValidEmail } from '@/lib/muzology';
import {
    beginCheckout,
    deleteLocalStorage,
    writeToLocalStorage
} from '@/components/Checkout/utils/CheckoutProcess';
import { useAnalytics } from '@/analytics/AnalyticsProvider';
import { identifyUser } from '@/lib/analytics';
import * as Events from '@/analytics/events';

import { createUrlWithParams } from '@/lib/urls';
import { NewParentRegister, NewTeacherRegister } from "@/analytics/events";

export const EmailSignup = ({
    id,
    className = '',
    buttonText = null,
    metadata = {},
    createTest = false,
    showMobile
}) => {
    const analytics = useAnalytics();
    const router = useRouter();
    const timestamp = Date.now();

    const defaultValues = {
        email: null,
        anonymousID: analytics.getAnonymousId(),
        // logRocketSessionURL: analytics.getLogrocketSessionURL(),
        metadata: {
            timestamp
        }
    };

    const [isLoading, setLoading] = React.useState(false);

    if (createTest || Boolean(process.env.NEXT_PUBLIC_FILL_TEST_DATA)) {
        defaultValues.email = `test-${timestamp}@muzology.com`;
    }

    const {
        register,
        handleSubmit,
        watch,
        setError,
        formState: { errors }
    } = useForm({ defaultValues });

    const emailValue = watch('email');

    const onSubmit = useCallback(
        (data) => {
            // determine the pathname so we can save in session storage
            const isParentsPage = router.pathname === '/parents';
            const isTeachersPage = router.pathname === '/teachers';

            // clear any previous checkout state
            deleteLocalStorage('checkout-state');

            // create analytics events
            identifyUser({ email: data.email });

            const event = {
                ...router.query,
                ...data,
                sourcePage: router.pathname,
                isParentsPage,
                isTeachersPage,
                // email,
                // teacherEmail,
                // token
            };

            // track this Email Signup event
            analytics.track(Events.EmailSignup, event);

            // user has identified themselves with an email
            analytics.identify({ email: data.email });

            setLoading(true);

            /* eslint-disable camelcase */
            const payload = {
                email: data.email,
                anonymous_id: analytics.getAnonymousId(),
                logrocket_session_url: analytics.getLogrocketSessionURL(),
                metadata: {
                    ...data.metadata,
                    anonymous_id: analytics.getAnonymousId(),
                    logrocket_session_url: analytics.getLogrocketSessionURL(),
                    location: window.location.href,
                    'input-id': id,
                    'query-string': router.query,
                }
            };
            /* eslint-enable camelcase */

            beginCheckout(payload)
                .then((response) => {
                    const { token, email, error, errors } = response;
                    // check for errors
                    if (error || errors) {
                        // create analytics event
                        analytics.track(Events.EmailSignupError, {
                            ...response,
                            isParentsPage,
                            isTeachersPage,
                            beginCheckout: isParentsPage
                        });

                        if (error) {
                            // a general error
                            console.error(error);
                            // setError('error', { type: 'manual', message: error });
                        } else if (errors) {
                            // a form error
                            for (const [key, value] of Object.entries(errors)) {
                                // console.log(key);
                                // console.log(value.toString());
                                // @ts-ignore
                                setError(key, { type: 'manual', message: value.toString() });
                            }
                        }
                        setLoading(false);
                        return;
                    }

                    let teacherEmail = false;
                    if (/\b\.org\b|\b\.net\b|\b\.edu\b/.test(data.email)) {
                        teacherEmail = true;
                    }

                    // determine where to go next - begin the checkout, teacher trial signup, or ask the user what their role is
                    let destPathname = '/onboarding';
                    if (isParentsPage)
                        destPathname = '/checkout';
                    else if (isTeachersPage)
                        destPathname = '/signup';

                    const event = {
                        ...router.query,
                        ...data,
                        email,
                        teacherEmail,
                        sourcePage: router.pathname,
                        isParentsPage,
                        isTeachersPage,
                        token
                    };

                    // all signups get email signup successful on the frontend
                    analytics.track(Events.EmailSignupSuccessful, event);

                    // if on the teachers page or email has org / net / edu send to teacher signup
                    if (isTeachersPage || teacherEmail) {
                        // on teachers page - go to teacher signup
                        // analytics.track(Events.NewTeacherRegister, { ...data, isParentsPage, beginCheckout: isParentsPage });
                        // window.location.href = createUrlWithParams('/signup', { email, token });
                        const url = createUrlWithParams('/signup', { ...router.query, email, token });
                        router.push(url, undefined, { shallow: false })
                            .then(() => {
                                analytics.track(Events.NewTeacherRegister, event);
                            });
                        // if on parents page
                    } else if (isParentsPage) {

                        // go to checkout page
                        const destPath = createUrlWithParams('/checkout', { ...router.query, email, token });

                        // save the token to local storage
                        if (token) {
                            console.log('Saving muzology-checkout state', response);
                            writeToLocalStorage('checkout-state', { pathname: destPath, ...response });
                        }

                        // window.location.href = destPath;
                        router.push(destPath, undefined, { shallow: false })
                            .then(() => {
                                analytics.track(Events.NewParentRegister, event);
                                analytics.track(Events.CheckoutBegins, event);
                            });

                    } else {
                        // on home page
                        const destPath = createUrlWithParams(destPathname, { ...router.query, email, token });
                        router.push(destPath, undefined, { shallow: false })
                            .then(() => {
                                analytics.track(Events.UserTypeSelectionBegins, event);
                            });
                    }

                })
                .catch((error) => {
                    setLoading(false);
                    console.error(error);
                    // setError('error', { type: 'manual', message: error });
                });
        },
        [analytics, id, router, setError]
    );

    // snag the onBlur event
    const props = register('email', { required: true, validate: { validEmail: checkValidEmail } });
    // const { onBlur } = props;

    const [focus, setFocus] = React.useState(false);
    const onBlur = (event) => {
        setFocus(false);
        return props.onBlur(event);
    };
    const onFocus = (event) => {
        setFocus(true);
    };

    return (
        <div id={id + '-container'}
            className={cx('hidden xs2:flex flex-col gap-4 m-auto w-full max-w-[600px]', { flex: showMobile })}>
            <div
                className={cx('hidden fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 justify-center items-center hidden', {
                    flex: isLoading
                })}
            ></div>
            <form id={id} name='Email Signup Form' onSubmit={handleSubmit(onSubmit)}
                className='flex flex-col sm:flex-row gap-4 w-full'>
                <div className='w-full'>
                    <input
                        id={Number(id) + '-input'}
                        placeholder={focus ? '' : 'Your email'}
                        {...props}
                        onBlur={onBlur}
                        onFocus={onFocus}
                        className={`${className} text-left placeholder-current w-[100%] lg:w-[100%] h-[54px] px-4 py-5 rounded-xl border-2 border-solid justify-between items-center inline-flex text-[17px]] font-normal font-Poppins leading-loose tracking-wide`}
                    />
                    {errors.email
                        && <div className='text-red-500 mt-1 text-sm md:text-[17px] text-center'>Please
                            enter valid email</div>}
                </div>

                <button
                    id={'submit-' + id}
                    className='whitespace-nowrap sm:max-w-[190px] lg:max-w-[213px] h-[54px] px-8 py-5 bg-[#6F5FE6] rounded-2xl border-solid border-[1px] border-none justify-center items-center gap-4 inline-flex hover:bg-indigo-600 hover:border-indigo-600 hover:shadow-lg hover:scale-105 transform transition-all duration-300 ease-in-out active:bg-indigo-700 active:border-indigo-700 active:shadow-none active:scale-100'
                >
                    <div
                        className='text-[#FCFCFC]  text-center text-neutral-50 text-[20px] font-semibold font-Poppins leading-loose tracking-wide'>
                        {buttonText ?? 'Get Started'}
                    </div>
                </button>
            </form>
        </div>
    );
};

export default EmailSignup;
