import React, { useState } from 'react';
import BalanceText from '@/components/BalanceText/BalanceText';
import { useRouter } from 'next/router';
import Image from 'next/image';
import EmailSignup from '@/components/PlatformSection/EmailSignup';
import dynamic from 'next/dynamic';
import BillboardImg from './hero_trustbar/billboard.png';
import NSFImg from './hero_trustbar/NSF.png';
import DOEImg from './hero_trustbar/DOE.png';
import USNEWSImg from './hero_trustbar/USNEWS.png';

const ReactPlayer = dynamic(() => import('react-player/lazy'), { ssr: false });

interface Props {
    heading?: string;
    subHeading?: string;
    videoLink?: string;
    content?: any;
    id?: string;
    featuredImage?: any;
}

// background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
// filter: drop-shadow(0px 0px 0.62785px rgba(38, 103, 255, 0.05)) drop-shadow(0px 10.0456px 15.0684px rgba(38, 103, 255, 0.09));
// border-radius: 20.0912px;

const VideoHero = ({ id, heading, subHeading, content, featuredImage, videoLink }: Props) => {
    const router = useRouter();
    const videoRef = React.useRef(null);
    const [playing, setPlaying] = useState(false);
    const [touched, setTouched] = useState(false);

    const toggleVideoPlaying = () => {
        if (playing) {
            setPlaying(false);
        } else {
            setPlaying(true);
        }
    };

    videoLink = 'https://videos.muzology.com/sample-math-music-videos/manifest.m3u8';
    videoLink = 'https://videos.muzology.com/sample-math-music-videos/manifest.m3u8';

    // videoLink = 'https://videos.muzology.com/sample-math-music-videos/manifest.mpd';
    // <source src={videoLink} type='application/x-mpegURL'/>
    // <source src={videoLink} type='video/mp4' />
    // <source src='/assets/video/Sample_Math_Music_Videos.mp4' type='video/mp4' />
    // <source src='/assets/video/sample_math_music_videos-(1080p).mp4' type='video/mp4' />

    const handleClickVideo = (event) => {
        if (!touched) {
            event.preventDefault();
            setTouched(true);
        }
    };

    const video = (
        <>
            <video
                autoPlay
                muted
                loop
                playsInline
                width='100%'
                height='auto'
                className='relative inline sm:hidden'
                controls={touched}
                onClick={handleClickVideo}
            >
                <source src='/assets/video/sample_math_music_videos-(360).mp4' type='video/mp4' />
            </video>
            <video
                autoPlay
                muted
                loop
                playsInline
                width='100%'
                height='auto'
                className='relative hidden sm:inline'
                controls={touched}
                onClick={handleClickVideo}
            >
                <source src='/assets/video/sample_math_music_videos-(1080p).mp4' type='video/mp4' />
            </video>
        </>
    );

    // <video width="352" height="198" controls>
    //     <source src="playlist.m3u8" type="application/x-mpegURL">
    // </video>

    const video2 = (
        <ReactPlayer
            style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
            }}
            config={{
                vimeo: {
                    playerOptions: {
                        playsinline: true,
                    }
                }
            }}
            playsinline={true}
            playing={true}
            url={videoLink}
            width='100%'
            height='100%'
            controls={true}
            muted={true}
            volume={0}
        />
    );

    return (
        <section
            id={id}
            className='VideoHeroBackground relative mt-[70px] z-20 bg-[#271556] pb-0 w-full lg:max-h-[795px] bg-cover bg-video-grid-hero-mobile md:bg-cover md:bg-video-grid-hero-large'
        >
            <div className='dark-overlay absolute inset-0 z-10' />

            <div className='relative max-w-[1600px] mx-auto px-8 sm:px-10 md:px-12'>
                <div className='relative z-10 flex flex-col lg:flex-row gap-4 sm:gap-16 py-11 sm:px-0 sm:pb-28 sm:pt-24 2xl:pt-28 mx-auto justify-center items-center w-full h-full max-w-[2000px]'>
                    <div className='hidden lg:flex basis-full w-full relative'>
                        <div className='w-full aspect-video max-w-[628px] relative ml-auto' onClick={toggleVideoPlaying}>
                            {video2}
                        </div>
                    </div>

                    <div className='basis-full w-full select-none'>
                        <div className='blur-box rounded-3xl lg:rounded-[50px] max-w-[628px] w-full m-auto'>
                            <div className='w-full h-full flex flex-col px-5 py-9 lg:px-10 xl:px-12 lg:py-8 xl:py-8 gap-4 lg:gap-5'>
                                <BalanceText
                                    as='h1'
                                    className='mt-0 mb-0 text-white text-center lg:text-left text-2xl sm:text-[30px] md:text-[25px] lg:text-[28px] xl:text-[35px] font-semibold leading-[120%] font-Poppins '
                                >
                                    {heading}
                                </BalanceText>

                                <div className='lg:hidden w-full aspect-video max-w-[628px] relative ml-auto' onClick={toggleVideoPlaying}>
                                    {video}
                                </div>

                                <div className='text-white text-[15px] sm:text-xl xl:text-[17px] text-left sm:text-left font-[300] font-Poppins leading-6 lg:leading-normal mb-0 '>
                                    {subHeading}
                                </div>
                                {/*
                                <StructuredText
                                    data={content}
                                    className='text-white balanced -mt-3 md:-mt-4 mb-6 sm:mb-6 md:mb-10'
                                />
                                */}

                                <div className='w-full'>
                                    <EmailSignup
                                        id={id + '-email-signup'}
                                        className='border-[#1CC8E1] text-white bg-[transparent] border-2 bg-opacity-100'
                                        showMobile={true}
                                    />
                                </div>

                                <div className='w-full max-w-[541px] xl:w-[541px] m-auto grid grid-cols-2 xs2:flex  gap-4 justify-center items-center'>
                                    <div className='max-w-[138px] xs2:max-w-[108px]  '>
                                        <Image src={BillboardImg} className='max-w-full h-auto' />
                                    </div>
                                    <div className='max-w-[138px] xs2:max-w-[108px]'>
                                        <Image src={USNEWSImg} className='max-w-full h-auto' />
                                    </div>
                                    <div className='max-w-[138px]'>
                                        <Image src={DOEImg} className='max-w-full h-auto' />
                                    </div>
                                    <div className='max-w-[138px]'>
                                        <Image src={NSFImg} className='max-w-full h-auto' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <style jsx>{`
                .blur-box {
                    background-color: #00000033;
                    -webkit-backdrop-filter: blur(45px);
                    backdrop-filter: blur(45px);
                }
            `}</style>
        </section>
    );
};

export default VideoHero;
