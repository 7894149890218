import React, { useState, useEffect, useRef } from 'react';
import { VideoPlayer } from '@/components/VideoPlayer';
import dynamic from 'next/dynamic';
import EmailSignup from '@/components/PlatformSection/EmailSignup';
import { Heading2, Heading3 } from '@/components/Views/Heading';
import BalanceText from '@/components/BalanceText/BalanceText';
import NormalText from '@/components/Views/Text';
import IntersectionObserverElement from '@/components/IntersectionObserverElement/IntersectionObserverElement';
import MockLaptop from '@/components/MockLaptop/MockLaptop';
import { useAnalytics } from '@/analytics/AnalyticsProvider';
import { VideoEnded, VideoPaused, VideoPlaying, VideoSeeked } from '@/analytics/events';
const ReactPlayer = dynamic(() => import('react-player/lazy'), { ssr: false });

const VideoSection = ({ id, heading, subHeading, text, videoLink, className = '', showPlayButton = true, showLaptopVideo = false }) => {
    const elementRef = useRef<HTMLDivElement | null>(null);
    const [elementIsVisible, updateElementIsVisible] = useState<any | null>(null);
    const [isPlaying, setPlaying] = useState(false);
    const analytics = useAnalytics();
    const videoRef = React.useRef(null);

    const onPlay = () => {
        if (isPlaying) {
            setPlaying(false);
        } else setPlaying(true);
    };

    useEffect(() => {
        if (elementIsVisible) {
            setPlaying(true);
        }
    }, [elementIsVisible]);

    const handlePlaying = (...x) => {
        console.log('video playing', x);
        analytics.track(VideoPlaying, { id, videoLink, heading });
        setPlaying(true);
    };

    const handlePause = (...x) => {
        console.log('video paused', x);
        analytics.track(VideoPaused, { id, videoLink, heading });
    };

    const handleSeek = (seconds) => {
        console.log('video seeked', seconds);
        analytics.track(VideoSeeked, { id, videoLink, heading, seconds });
    };

    const onEnded = (...x) => {
        console.log('video ended', x);
        analytics.track(VideoEnded, { id, videoLink, heading });
    };

    return (
        <section
            id={id}
            className={className + ' w-full default-py default-px pt-10 text-center '}
            style={{ background: 'url(/backgrounds/section2.png)', backgroundSize: 'cover' }}
        >
            <div className='default-max-w  mx-auto'>
                <Heading3 className='text-center text-white font-semibold md:text-center mt-0 md:mt-10 mx-auto font-Poppins' heading={heading} />

                <div className='text-white text-lg sm:text-xl md:text-[24px] font-normal font-Poppins mt-1 md:mt-0 mb-0 sm:mb-7 leading-normal md:leading-[55px]'>
                    {subHeading}
                </div>
                {showLaptopVideo ? (
                    <div className='h-full md:h-[500px] text-left mb-[-30px]'>
                        <MockLaptop />
                    </div>
                ) : (
                    <div className='w-full lg:max-h-[500px] aspect-video relative mt-5 md:m-auto py-0 md:py-7'>
                        <IntersectionObserverElement updateElementIsVisible={updateElementIsVisible} classNames='' ref={elementRef} />

                        <ReactPlayer
                            ref={videoRef}
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0
                            }}
                            playing={elementIsVisible && true}
                            url={videoLink}
                            config={{
                                vimeo: {
                                    playerOptions: {
                                        playsinline: true
                                    }
                                }
                            }}
                            // width={width}
                            // height={height}
                            width='auto'
                            height='100%'
                            controls={true}
                            playsinline={true}
                            muted={true}
                            onPlay={handlePlaying}
                            onPause={handlePause}
                            onSeek={handleSeek}
                            onEnded={onEnded}
                        />
                    </div>
                )}
                {text && (
                    <div className='mt-0 sm:mt-7'>
                        <NormalText
                            balanced={true}
                            className='text-white text-base md:text-lg font-normal font-Poppins text-center leading-[1.65] md:leading-[28px] mt-8 sm:mt-0 max-w-[1100px] mx-auto'
                            // className='text-white text-center sm:text-left font-Poppins mt-5 mx-auto'
                            style={{ fontFamily: 'Poppins' }}
                            text={text}
                        />
                    </div>
                )}

                <div className='w-full flex xs2:pt-10 xs2:2mb-[100px]'>
                    <EmailSignup id='email-signup-video-section' className='border-[#1CC8E1] text-white bg-[transparent]' showMobile={false} />
                </div>
            </div>
        </section>
    );
};

export default VideoSection;
