import classNames from 'classnames';
import React, { useEffect, useRef, forwardRef, ForwardedRef } from 'react';

interface Props {
    updateElementIsVisible?: any;
    classNames?: any;
}

const IntersectionObserverElement = forwardRef(({ updateElementIsVisible, classNames }: Props, ref: ForwardedRef<HTMLDivElement>) => {
    useEffect(() => {
        const observer = new IntersectionObserver((entries, observer) => {
            const entry = entries[0];
            updateElementIsVisible(entry.isIntersecting);
        });
        // @ts-ignore
        observer.observe(ref.current);
    }, [ref]);
    return <div ref={ref} className={`${classNames}`} />;
});

export default IntersectionObserverElement;
